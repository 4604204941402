<template>
  <div class="maincol">
    <div id="main">
      <p v-if="!rules.length && rows.length > 0"
         class="text-info text-center infotext font-weight-bold">
        The filter does no apply to any rule.
      </p>
      <table v-for="row in rules" :key="row.characteristics.id" :id="row.characteristics.id"
             cellpadding="0"
             cellspacing="0"
             class="ruletable">
        <tbody>
        <tr class="row_even">
          <td colspan="2" class="rulehead">
            Rule {{ row.characteristics.id }}
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell ruletitle">
            Title
          </td>
          <td class="valcell ruletitle">
            {{ row.characteristics.title }}
          </td>
        </tr>
        <tr class="row_even">
          <td colspan="2" class="ruledescriptions">
            Description
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell ruledescription">
            Short
          </td>
          <td class="valcell ruledescription">
            {{ row.description.short }}
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell ruledescription">
            Extended
          </td>
          <td class="valcell ruledescription" v-html="row.description.extended">

          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell ruledescription">
            Exploit
          </td>
          <td class="valcell ruledescription" v-html="row.description.exploit">
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell ruledescription">
            Rule
          </td>
          <td class="valcell ruledescription ruleitself">
            <div>
              <pre>{{ row.description.rule }}</pre>
            </div>
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell ruledescription">
            Link to rule
          </td>
          <td class="valcell ruledescription">
            <a :href="row.description.rulelink"
               target="_blank">{{ row.characteristics.file }}/{{ row.characteristics.id }}</a>
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell ruledescription">
            Build instructions
          </td>
          <td class="valcell ruledescription">
            {{row.build_instructions}}
          </td>
        </tr>
        <tr class="row_even">
          <td colspan="2" class="rulecharacteristics">
            Characteristics
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell rulecharacteristickey">
            Rule ID
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.id }}
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell rulecharacteristickey">
            Phase
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.phase }}
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell rulecharacteristickey">
            Paranoia level
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.paranoia_level }}
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell rulecharacteristickey">
            Severity
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.severity }}
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell rulecharacteristickey">
            File
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.file }}
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell rulecharacteristickey">
            Tags
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.tags.join(', ') }}
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell rulecharacteristickey">
            Siblings
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.siblings.join(', ') }}
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell rulecharacteristickey">
            Targets
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.targets.join(', ') }}
          </td>
        </tr>
        <tr class="row_odd">
          <td class="keycell rulecharacteristickey">
            Rule operator
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.operator.join(', ') }}
          </td>
        </tr>
        <tr class="row_even">
          <td class="keycell rulecharacteristickey">
            Rule chained
          </td>
          <td class="valcell rulecharacteristicval">
            {{ row.characteristics.chained }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>

import {each} from "lodash";
import {useStore} from "@/store";
import {storeToRefs} from "pinia";
import {onBeforeMount} from "vue";

const store = useStore()

// recalculate the share url on every direct mutation the store
store.$subscribe((mutation)=>{
  if (mutation?.type === "direct") {
    calc_share_url()
  }
})

/**
 * Calculate the share url's multi rules
 * @param url_key string
 * @param key string
 * @param share_url URL
 *
 * we have filter array like [ '@eq', '@req' ]
 * we have dropdown object like { '@eq': 12, '@req': 13 }  as {key: count}
 *     '@eq' is 0th index
 *     '@req' is 1th index
 *
 * so this function picks the indexes of the filtered values and sets the share_url's url_key value.
 * e.g.: localhost?url_key=1,3,5&url_key_2=2,4,6
 *
 * if there are more unchecked then checked, then we collect the checked indexes to url_key like '_tr'. (mean the underscore!)
 * else if there are more checked then checked, then we collect the checked indexes to url_key like 'tr'.
 */
function calc_multi(url_key,key,share_url) {
  if (store.filter[key].length && store.filter[key].length < Object.keys(store.dropdown[key]).length) {
    let ops = url_key, elems = []
    // if unchecked is more then checked, collected checked
    if (store.filter[key].length < Object.keys(store.dropdown[key]).length / 2) {
      ops = '_'+url_key;
      each(store.filter[key], (elem) => {
        elems.push(Object.keys(store.dropdown[key]).indexOf(elem))
      })
    }
    else {
      // else collect unchecked
      each(Object.keys(store.dropdown[key]), (elem) => {
        if (store.filter[key].indexOf(elem) === -1) {
          elems.push(Object.keys(store.dropdown[key]).indexOf(elem))
        }
      })
    }

    share_url.searchParams.set(ops, elems.join(','))
  }
}

/**
 * calculate the share url from the filtered values.
 * it sets the share_url in the store and makes is visible in the bottom of the filter div.
 */
function calc_share_url() {
  let share_url = new URL(window.location.origin)
  share_url.searchParams.set('v',store.version) // set the version
  share_url.searchParams.set('f','1') // set f (filter) = 1 // to be observed in the onMount hook

  // set the simple filters
  if (store.filter.phase) share_url.searchParams.set('ph',store.filter.phase)
  if (store.filter.paranoia_level) share_url.searchParams.set('pl', store.filter.paranoia_level)
  if (store.filter.rule_chained !== '0') share_url.searchParams.set('rc', store.filter.rule_chained)
  if (store.filter.severity !== '') share_url.searchParams.set('sv' , store.filter.severity)

  // calculate the complex filters
  calc_multi('ro','rule_operators',share_url)
  calc_multi('tg','tags',share_url)
  calc_multi('trg','targets',share_url)

  // set the share url in the store.
  store.$patch({share_url: {orig: share_url.href, decoded: decodeURIComponent(share_url.href)}})
}



function apply_multi(url_key,key,updates) {
  const url = new URL(document.location.href)
  const negate = url.searchParams.get('_'+url_key)
  if (negate) url_key='_'+url_key

  if (url.searchParams.get(url_key)) {
    const values = url.searchParams.get(url_key).split(',')
    updates[key] = []

    if (negate) {
      each(values, (elem) => {
        updates[key].push(Object.keys(store.dropdown[key])[elem])
      })
    } else {
      each(Object.keys(store.dropdown[key]), (elem, idx) => {
        if (values.indexOf(idx.toString()) === -1) updates[key].push(elem)
      })
    }
  }
}

function apply_share_url() {
  const url = new URL(document.location.href)
  const updates = {}

  if (url.searchParams.get('v')) {
    store.version = url.searchParams.get('v')
  }

  if (url.searchParams.get('ph')) updates.phase = url.searchParams.get('ph')
  if (url.searchParams.get('pl')) updates.paranoia_level = url.searchParams.get('pl')
  if (url.searchParams.get('rc')) updates.rule_chained = url.searchParams.get('rc')
  if (url.searchParams.get('sv')) updates.severity = url.searchParams.get('sv')

  apply_multi('ro','rule_operators',updates)
  apply_multi('tg','tags',updates)
  apply_multi('trg','targets',updates)

  store.$patch({
    filter: updates,
    share_url: {
      orig: url.href,
      decoded: decodeURIComponent(url.href)
    }
  })
}

onBeforeMount(() => {
  if (new URL(window.location.href).searchParams.get('f') === '1') {
    apply_share_url()
  }
})

const {rules, rows} = storeToRefs(store)

</script>

<style lang="scss">
.mainrow {
  display: flex;
  justify-content: space-between;

  .maincol {
    &:nth-child(1) {
      flex-basis: 25%;
    }

    &:nth-child(2) {
      flex-basis: 40%;
    }

    &:nth-child(3) {
      flex-basis: 35%;
    }

    padding-top: 32px;

    #main {
      overflow-y: auto;
      height: calc(100vh - 32px);
    }
  }
}

table.ruletable {
  text-align: center;
  width: 100%;
  margin-bottom: 32px;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-left: 2px solid #0085ba;
  border-right: 2px solid #0085ba;

  .filehook {
    visibility: hidden;
  }

  .row_even {
    background-color: lightgrey;
  }

  .row_odd {
    background-color: white;
  }

  .rulehead, .rulecharacteristics, .ruledescriptions {
    background-color: #0085ba;
    color: white;
    font-weight: bold;
  }

  .rulehead {
    font-size: 1.6em;
  }

  .rulecharacteristics, .ruledescriptions {
    font-size: 1.2em;
  }

  .keycell {
    width: 150px;
    vertical-align: top;
  }

  .valcell {
    width: 100%;
    vertical-align: top;
  }

  .ruleitself div {
    width: 100%;
    font-family: monospace, monospace;
    overflow-y: auto;
  }

  .ruleitself pre {
    margin-top: 0;
    overflow: auto;
    max-width: 27vw;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;

    &:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    &:last-of-type {
      border-bottom: 2px solid #0085ba;
    }

    td {
      padding: 2px;
      text-align: left;
      max-width: 27vw;
      overflow-x: auto;
    }
  }
}
</style>
