<template>
  <div class="modal" tabindex="-1" id="tags-modal" data-close="">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Filter by tags</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="filter">
            <label class="control-label" for="select-tags">Select tags by content</label>
            <div>
              <input type="text" class="form-control" id="select-tags" @keyup="select_tags"
                     v-model="filter_tags">
            </div>
          </div>
          <div class="div mb-4">
                      <span v-if="!state.toggling" role="button" @click="toggle_tags">Toggle all <img class="icon tags-toggle-all"
                                                                               :src="require('@/public/img/switch.png')"
                                                                               alt="Toggle all"></span>
            <span v-else>toggling...</span>
          </div>
          <div class="tags">
            <label class="check-label" :for="'f-tag-'+i" v-for="(pcs, tag, i) in dropdown.tags"
                   :key="i">
              <input type="checkbox" :id="'f-tag-'+i" :value="tag" v-model="filter.tags"
                     :checked="filter.tags.indexOf(tag) > -1"><span></span>{{ tag }} ({{ pcs }})
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Filter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "@/store";
import {storeToRefs} from "pinia";
import {reactive} from "vue";

const store = useStore()

const {filter, dropdown, filter_tags} = storeToRefs(store)
let filter_tags_timer = null

const state = reactive({toggling: false})

/**
 * select the tags checkboxes based on the filter_tags input
 *
 * use timeout for debouncing the input with 600ms (run only is 600ms has passed since the last keystroke)
 */
function select_tags() {
  if (filter_tags_timer) clearTimeout(filter_tags_timer)

  filter_tags_timer = setTimeout(() => {
    let base = dropdown.tags
    // filter by lowercase input
    filter.tags = base.filter(el => el.toLowerCase().indexOf(filter_tags) > -1)
  }, 600)
}

/**
 * (un)toggle all tags checkboxes
 */
function toggle_tags() {
  state.toggling = true
  new Promise(function (resolve) {
    setTimeout(() => {
      state.toggling = false
      resolve()
    }, 600)
  }).then(() => {
        if (filter.value.tags.length === Object.keys(dropdown.value.tags).length) {
          filter.value.tags = []
          document.querySelector('.tags-toggle-all').classList.add('invert')
        }
        else {
          filter.value.tags = [...Object.keys(dropdown.value.tags)]
          document.querySelector('.tags-toggle-all').classList.remove('invert')
        }
      }
  )

}

</script>

<style scoped>

</style>
