// load bootstrap css library
import "bootstrap/dist/css/bootstrap.min.css";

import { createApp } from 'vue'

// load the store, this keeps the variables what we use in the app
import { createPinia } from 'pinia'

import App from './App.vue'
const pinia = createPinia()

// mount the app
createApp(App).use(pinia).mount('#app')

// import bootstrap js library (modals, collapse, etc)
import 'bootstrap/dist/js/bootstrap.min.js'
