<template>
  <div id="app">
    <div class="container-fluid">
      <div v-if="!loaded && !load_error" class="loader">
        <p class="fw-bold p-5">Loading data, please wait <span id="dotdot">{{ dotdot }}</span></p>
      </div>
      <div v-else-if="load_error" class="loader">
        <p class="text-danger">{{ load_error }}</p>
      </div>
      <div class="mainrow" v-else>
        <sidebar></sidebar>
        <main-component></main-component>
        <Filter></Filter>
        <TagsModal></TagsModal>
        <TargetsModal></TargetsModal>
      </div>
      <div id="version" v-if="store.versions.length">
        <select id="versions" v-model="actual_version">
          <option :value="v" :key="v" v-for="v in store.versions">{{v}}</option>
        </select>
      </div>
      <div id="filter-sticky">
        Filtering {{ rules.length }}/{{ rows.length }}
      </div>
    </div>
  </div>
</template>

<script setup>
import MainComponent from "@/components/Main.vue";
import Sidebar from "@/components/Sidebar";
import Filter from "@/components/Filter";
import TargetsModal from "@/components/TargetsModal";
import TagsModal from "@/components/TagsModal";
import {useStore} from "./store/index";
import {storeToRefs} from "pinia";
import {computed} from "vue";

// load the store
const store = useStore()

// load store variables as constants, make them available below and in the html upper
const {dotdot, loaded, load_error, rules, rows} = storeToRefs(store)

let version = 'v3.4.0-dev'

let url = new URL(document.location.href)

const actual_version = computed({
  get() {
    return store.version
  },
  set(value) {
    const url = new URL(document.location.href)
    url.searchParams.set('v', value)
    document.location.href=url.href
    // store.load()
  }
})

// set the version in the store to the default OR the v GET param's value
store.$patch({version: url.searchParams.get('v') || version})

// load the versioned data
store.load()

</script>

<style lang="scss">

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 13px;
  width: 100%;
}

ul, li, p, a {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 13px;
}


.icon {
  width: 16px;
  height: 16px;
}

.check-label {
  display: inline-block;
  margin-right: 12px;

  span {
    margin-right: 8px;
  }
}



#filter-sticky {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 320px;
  background-color: #0085ba;
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  z-index: 1060;
}

[class*="-toggle-all"] {
  transition-duration: 0.3s;

  &.invert {
    transform: rotate(180deg);
  }
}

.loader {
  position: fixed;
  width: 480px;
  top: 120px;
  left: calc(50% - 240px);

  p {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
}

.infotext {
  font-size: 18px;
}
#version {
  background-color: #0085ba;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  padding: 4px 8px;
  border-bottom-left-radius: 8px;
}
</style>
