<template>
  <div class="maincol">
    <div id="toclist">
      <ul id="toc_parent" class="toclist">
        <li v-for="(list,key, idx) in contentblocks" :key="key" ref="toclist"
            :class="{hide: !contentblocks_active.includes(key)}">
          <a :href="'#'+key" :data-toc="key"
             @click="store.active_toc = key" data-bs-toggle="collapse" :data-bs-target="'#tt-'+idx"
             :class="{ active: active_toc===key }"
          >{{ key }}</a>
          <ul class="collapse" aria-expanded="false" :id="'tt-'+idx">
            <li v-for="el in list" :key="el">
              <a :href="'#'+el" @click="store.active_tocsub = el" :data-id="el" class="tocsub"
                 :class="{ active: active_tocsub === el, hide: rule_ids.indexOf(el)===-1 }"
              >{{ el }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "@/store";
import {computed, onMounted} from "vue";
import {each, intersection} from "lodash";
import {storeToRefs} from "pinia";

const store = useStore()
const {rules, rows, contentblocks,active_tocsub,active_toc} = storeToRefs(store)

// scroll to active toc on window load
// retrigger click event on toc to show active toc
onMounted(()=>{
  if (document.location.hash) {
    const hash = document.location.hash.substring(1)
    document.location.hash = ''
    setTimeout(function () {
      document.location.hash = hash

      // trigger the clicks on the sidebar list
      each(contentblocks.value, (list, key)=>{
        if (list.includes(parseInt(hash))) {
          document.querySelector(`a[data-toc="${key}"]`).click()
          document.querySelector(`a[data-toc="${key}"]`).parentElement.querySelector(`a[data-id="${hash}"]`)?.click()
        }
      })
    }, 125)
  }
})

// trigger the click on the first sub item on clicking parent
store.$subscribe((mutation)=>{
  if (mutation?.events?.key === 'active_toc') {
    const id = store.contentblocks[mutation.events.newValue][0]
    store.active_tocsub = id
    document.location.hash = '#'+id
  }
})

// gather the ids of all rules as an array
const rule_ids = computed(() => {
  let ids = []
  each(rules.value, rule => {
    ids.push(rule.characteristics.id)
  })

  return ids
})

// compute the active blocks to show on the sidebar
const contentblocks_active = computed(() => {
  if (!rows.value.length) return []
  let blocks = []
  each(contentblocks.value, (ids, blockname) => {
    if (intersection(ids, rule_ids.value).length > 0) blocks.push(blockname)
  })

  return blocks
})
</script>

<style scoped lang="scss">

#toclist {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 10px;
  height: calc(100vh - 48px);
  overflow-y: auto;

  > ul {
    padding-left: 0;
  }

  > ul > li.hide {
    display: none;
  }

  a.active {
    font-weight: bold;
    text-decoration: underline;
  }

  a.hide {
    display: none;
    text-decoration: line-through;
    color: grey;
  }
}

#toclist ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
