import {defineStore, acceptHMRUpdate} from 'pinia'
import {each, flatMap, intersection, sortBy} from "lodash";

const severity_map = {
    '-WARNING': ['NOTICE', 'WARNING'],
    '-CRITICAL': ['NOTICE', 'WARNING', 'CRITICAL'],
}

export const useStore = defineStore('modsec', {
    state: () => {
        return {
            share_url: {
                orig: '',
                decoded: '',
            },
            goto_hash: '',
            dotdot: '...',
            loaded: false,
            load_error: false,
            active_toc: null,
            active_tocsub: null,
            contentblocks: {},
            filter_tags: '',
            filter_tags_timer: null,
            filter_targets: '',
            filter_targets_timer: null,
            dropdown: {
                tags: {},
                targets: {},
                rule_operators: {},
            },
            rows: [],
            filter: {
                title: '',
                id: '',
                phase: '', // 1-5
                paranoia_level: '', // 0(N/A)-4 itt azt kell tudni, hogy ami a 4-es szinten aktiválva van, az az 1-esen is
                severity: '', // CRITICAL, WARNING, NOTICE
                tags: [], // multiple
                targets: [], // multiple
                rule_operators: [], // multiple
                rule_chained: '0' // 0,1,-1 (hide)
            },
            version: '',
            versions: [],
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        set_version(version) {
            this.version = version
        },
        reset_config() {
            const [v,vv]=[this.version,this.versions]
            this.$reset()
            this.version = v
            this.versions = vv
        },
        async load() {
            await this.load_versions()
            this.reset_config()
            let res
            this.load_error=''
            this.loading=true
            if (process.env.NODE_ENV === 'local') {
                res = await import(`@/assets/public/data/${this.version}/merged.json`).catch(err => {
                    this.load_error = 'Failed to load data! Version not found: ' + this.version
                    console.error('catch: failed to load', err)
                })
            }
            else {
                res = await fetch(`/public/data/${this.version}/merged.json`)
                    .then(res => res.json(), rej => {
                            this.load_error = 'Failed to load data!'
                            console.error('failed to load', rej)
                        }
                    ).catch(err => {
                        this.load_error = 'Failed to load data! Version not found: ' + this.version
                        console.error('catch: failed to load', err)
                    })
            }
            
            if (!res) {
                return console.error('failed to load data')
            }
            
            Array.prototype.forEach.call(res, (data) => {
                this.rows.push(data)
                if (!(data.characteristics.file in this.contentblocks)) {
                    this.contentblocks[data.characteristics.file] = [data.characteristics.id]
                }
                else {
                    this.contentblocks[data.characteristics.file].push(data.characteristics.id)
                }
                each(this.contentblocks, (block) => {
                    sortBy(block)
                })
            })
            
            await this.load_filters()
            return true
        },
        async load_versions() {
            let res
            if (process.env.NODE_ENV === 'local') {
                res = await import(`@/assets/public/data/versions.json`).catch(err => {
                    this.load_error = 'Failed to load data! Version not found: ' + this.version
                    console.error('catch: failed to load', err)
                })
            }
            else {
                res = await fetch(`/public/data/versions.json`)
                    .then(res => res.json(), rej => {
                            this.load_error = 'Failed to load data!'
                            console.error('failed to load', rej)
                        }
                    ).catch(err => {
                        this.load_error = 'Failed to load data! Version not found: ' + this.version
                        console.error('catch: failed to load', err)
                    })
            }
            
            if (!res) {
                return console.error('failed to load versions', res)
            }
            this.versions=Array.from(res)
        },
        async load_filters() {
            let res
            
            if (process.env.NODE_ENV === 'local') {
                res = await import(`@/assets/public/data/${this.version}/filters.json`)
            }
            else {
                res = await fetch(`/public/data/${this.version}/filters.json`).then(res => res.json(), rej => {
                        this.load_error = 'Failed to load data!'
                        console.error('failed to load', rej)
                    }
                ).catch(err => {
                    this.load_error = 'Failed to load data!'
                    console.error('failed to load', err)
                })
            }
            
            this.dropdown.targets = res.targets.count
            this.dropdown.tags = res.tags.count
            this.dropdown.rule_operators = res.operators.count
            
            this.targets = [...res.targets.values]
            this.tags = [...res.tags.values]
            this.rule_operators = [...res.operators.values]
            
            this.filter.targets = [...res.targets.values]
            this.filter.tags = [...res.tags.values]
            this.filter.rule_operators = [...res.operators.values]
            
            this.loaded = true
            
            return true
            
        }
    },
    getters: {
        has_filter(state) {
            return state.filter.phase !== '' ||
                state.filter.paranoia_level !== '' ||
                state.filter.severity !== '' ||
                state.filter.rule_chained !== '0' ||
                state.filter.tags.length !== Object.keys(state.dropdown.tags).length ||
                state.filter.targets.length !== Object.keys(state.dropdown.targets).length ||
                state.filter.rule_operators.length !== Object.keys(state.dropdown.rule_operators).length
        },
        rules() {
            if (!this.has_filter) return this.rows
            let rows = [...this.rows]
            
            if (this.filter.phase !== '')
                rows = rows.filter(row => row.characteristics.phase == this.filter.phase)
            
            if (this.filter.rule_chained !== '0') {
                let chained_value = this.filter.rule_chained === '1' ? 'Yes' : 'No'
                rows = rows.filter(row => row.characteristics.chained === chained_value)
            }
            
            if (this.filter.paranoia_level !== '') {
                if (this.filter.paranoia_level.substr(0, 1) !== '-') {
                    rows = rows.filter(row => row.characteristics.paranoia_level == this.filter.paranoia_level)
                }
                else {
                    let no = parseInt(this.filter.paranoia_level.substr(1, 1))
                    rows = rows.filter(row => row.characteristics.paranoia_level <= no)
                }
            }
            
            if (this.filter.severity !== '') {
                if (this.filter.severity.substr(0, 1) !== '-') {
                    rows = rows.filter(row => row.characteristics.severity === this.filter.severity)
                }
                else {
                    let severities = severity_map[this.filter.severity]
                    rows = rows.filter(row => severities.includes(row.characteristics.severity))
                }
            }
            
            if (this.filter.tags.length !== Object.keys(this.dropdown.tags).length)
                rows = rows.filter(row => row.characteristics.tags.length > 0 && intersection(flatMap(row.characteristics.tags), (this.filter.tags)).length > 0)
            
            if (this.filter.targets.length !== Object.keys(this.dropdown.targets).length)
                rows = rows.filter(row => row.characteristics.targets.length > 0 && intersection(flatMap(row.characteristics.targets), (this.filter.targets)).length > 0)
            
            if (this.filter.rule_operators.length !== Object.keys(this.dropdown.rule_operators).length)
                rows = rows.filter(row => row.characteristics.operator.length > 0 && intersection(flatMap(row.characteristics.operator), (this.filter.rule_operators)).length > 0)
            
            return rows
        },
    }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
