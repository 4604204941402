<template>
  <div class="maincol">
    <div class="filter-parent" id="filter-parent">
      <div class="filter">
        <h1>Filter</h1>
        <div class="form">
          <div class="form-group">
            <label for="phase">Phase</label>
            <select class="form-control" v-model="filter.phase" id="phase">
              <option value="">- ALL -</option>
              <option :value="value" v-for="value in 5" :key="value">{{ value }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="paranoia">Paranoia level</label>
            <select class="form-control" v-model="filter.paranoia_level" id="paranoia">
              <option value="">- ALL -</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="-1">&lt;=1</option>
              <option value="2">2</option>
              <option value="-2">&lt;=2</option>
              <option value="3">3</option>
              <option value="-3">&lt;=3</option>
              <option value="4">4</option>
              <option value="-4">&lt;=4</option>
            </select>
          </div>
          <div class="form-group">
            <label for="paranoia">Severity</label>
            <select class="form-control" v-model="filter.severity" id="severity">
              <option value="">- ALL -</option>
              <option value="NOTICE">NOTICE</option>
              <option value="WARNING">WARNING</option>
              <option value="-WARNING">&lt;=WARNING</option>
              <option value="CRITICAL">CRITICAL</option>
              <option value="-CRITICAL">&lt;=CRITICAL</option>
            </select>
          </div>
          <div class="form-group">
            <label class="check-label" for="rulechanined">Rule chained</label>
            <select id="rulechanined" class="form-control" v-model="filter.rule_chained">
              <option value="0">- ALL -</option>
              <option value="-1">hide chained</option>
              <option value="1">only chained</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="filter-operators">Rule operators <span @click="toggle_operators"><img
                  class="icon operators-toggle-all" :src="require('@/public/img/switch.png')"
                  alt="Toggle all"></span></label>
              <div>
                <label class="check-label" :for="'rop-'+i"
                       v-for="(pcs, trg, i) in dropdown.rule_operators"
                       :key="i">
                  <input class="" type="checkbox" v-model="filter.rule_operators" :id="'rop-'+i"
                         :value="trg"><span></span>
                  {{ trg }} ({{ pcs }})
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="my-3 modaltoggle">
          <a data-bs-toggle="modal" data-bs-target="#targets-modal" href="#" onclick="return false"
             role="button">Targets
            <img :src="require('@/public/img/resize.png')" class="icon" alt="open targets modal"></a>

        </div>
        <div class="mb-3 modaltoggle">
          <a data-bs-toggle="modal" data-bs-target="#tags-modal" href="#" onclick="return false" role="button">Tags
            <img :src="require('@/public/img/resize.png')" class="icon" alt="open tags modal"></a>
        </div>
        <hr>
        <button type="button" class="btn btn-danger" @click="reset_filter">Reset filter</button>
        <template v-if="store.has_filter">
          <hr>
          <p>
            <b>share url: </b><a target="_blank" :href="share_url.orig">{{share_url.decoded}}</a>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "@/store";
import {storeToRefs} from "pinia";
import {computed} from "vue";

const store = useStore()
store.$subscribe(() => {
})

const {dropdown,share_url} = storeToRefs(store)

// filter variable is computed from store
const filter = computed({
  get() {
    return store.filter
  },
  set(value) {
    store.$patch({'filter': value})
  }
})

// (un)toggle the rule_operators checkboxes
function toggle_operators() {
  if (filter.value.rule_operators.length === Object.keys(dropdown.value.rule_operators).length) {
    store.filter.rule_operators = []
    document.querySelector('.operators-toggle-all').classList.add('invert')
  }
  else {
    store.filter.rule_operators = [...Object.keys(dropdown.value.rule_operators)]
    document.querySelector('.operators-toggle-all').classList.remove('invert')
  }
}

// set the default values of the filter and clean the URL
function reset_filter() {
  store.filter.targets = [...Object.keys(dropdown.value.targets)]
  store.filter.tags = [...Object.keys(dropdown.value.tags)]
  store.filter.rule_operators = [...Object.keys(dropdown.value.rule_operators)]
  store.filter.phase = ''
  store.filter.paranoia_level = ''
  store.filter.severity = ''

  // clean the url
  window.history.pushState({}, document.title, window.location.pathname);
}

</script>

<style scoped lang="scss">
.teszt {
  background-color: red;
}

.filter-parent {
  width: 100%;
  padding-left: 12px;
}

[data-bs-toggle="modal"] {
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;

  img {
    margin-left: 8px;
  }
}

.filter {
  display: block;
  padding: 24px;
  border: 1px solid lightgrey;
  width: 100%;

  h1 {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid lightgrey;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      flex-basis: 48%;
      margin-bottom: 12px;
    }

    label:not(.check-label) {
      display: block;
      margin-bottom: 4px;
      font-weight: bold;
    }

    input[type=text], select {
      width: 100%;
    }
  }
}
</style>
