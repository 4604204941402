<template>
  <div class="modal" tabindex="-1" id="targets-modal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Filter by targets</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="filter">
            <label class="control-label" for="select-tags">Select targets by content</label>
            <div>
              <input type="text" class="form-control" id="select-tags" @keyup="select_targets"
                     v-model="filter_targets">
            </div>
          </div>
          <div class="mb-4">
                      <span v-if="!state.toggling" @click="toggle_targets" role="button">Toggle all <img
                          class="icon target-toggle-all"
                          :src="require('@/public/img/switch.png')"
                          alt="Toggle all"></span>
            <span v-else>toggling...</span>
          </div>
          <div class="tags">
            <div>
              <label class="check-label" :for="'trg-'+i" v-for="(pcs, trg, i) in dropdown.targets"
                     :key="i">
                <input class="" type="checkbox" v-model="filter.targets" :id="'trg-'+i"
                       :value="trg"><span></span>
                {{ trg }} ({{ pcs }})
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Filter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "@/store";

import {storeToRefs} from "pinia";
import {reactive} from "vue";

const store = useStore()

const {filter, dropdown, filter_targets} = storeToRefs(store)
const state = reactive({toggling: false})

/**
 * (un)toggle all targets checkboxes
 */
function toggle_targets() {
  state.toggling = true
  new Promise(function (resolve) {
    setTimeout(() => {
      state.toggling = false
      resolve()
    }, 600)
  }).then(() => {
        if (filter.value.targets.length === Object.keys(dropdown.value.targets).length) {
          filter.value.targets = []
          document.querySelector('.target-toggle-all').classList.add('invert')
        }
        else {
          filter.value.targets = [...Object.keys(dropdown.value.targets)]
          document.querySelector('.target-toggle-all').classList.remove('invert')
        }
      }
  )

}

let filter_targets_timer = null

/**
 * filter targets by input: #filter_targets
 *
 * debounce to 600ms from the last keystroke
 */
function select_targets() {
  if (filter_targets_timer) clearTimeout(filter_targets_timer)

  filter_targets_timer = setTimeout(() => {
    let base = dropdown.targets
    filter.targets = base.filter(el => el.toLowerCase().indexOf(filter_targets) > -1)
  }, 600)
}

</script>

<style scoped>

</style>
